import * as React from "react";
import { Layout, Title } from "../components";

const IndexPage = () => {
  return (
    <>
      <title>
        Dienstverlening | Cémpes Vastgoed - Dé alleskunner voor uw
        vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="STERK IN VASTGOEDBEHEER- EN ONTWIKKELING" />

        <div className="grid sm:grid-cols-3 gap-6 mt-2">
          <div>
            <img src="/img/services/image.jpg" alt="services" />
          </div>
          <div>
            <p>
              Bij de realisatie van vastgoedprojecten, of het nu om woningbouw
              of utiliteitsbouw gaat, doorloopt u verschillende fasen waarbij
              gedegen kennis en kunde van belang is om uw project succesvol tot
              uitvoering te brengen. Steeds vaker zien wij dat
              projectontwikkelaars, woningbouwcorporaties en gemeentes niet
              voldoende capaciteit hebben om hun vastgoedontwikkelingen optimaal
              te begeleiden. Vaak hebben particuliere opdrachtgevers niet alle
              kennis in huis om de verschillende fasen succesvol te doorlopen.
              Cémpes Vastgoed is de partner voor de begeleiding van al uw
              vastgoedprojecten.
            </p>
          </div>
          <div>
            <strong>Cémpes Vastgoed is gespecialiseerd in:</strong>
            <ul className="list-disc list-inside">
              <li>Projectontwikkeling</li>
              <li>Projectmanagement</li>
              <li>Contractmanagement</li>
              <li>Inkoop</li>
              <li>Geschil- en conflictmanagement</li>
              <li>Facilitair management</li>
              <li>Bouwkostendeskundige</li>
              <li>Directievoering</li>
              <li>Meerjarenonderhoud</li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
